<template>
  <div class="m-companies-list">
    <b-notification
      v-if="deleteError"
      type="is-danger"
      has-icon
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('companies.companyDeleteError') }}
    </b-notification>
    <b-table :data="companies" :paginated="true" :per-page="20" :loading="loading" default-sort="name">
      <b-table-column field="id" label="ID" sortable v-slot="props">
        <router-link :to="{ name: 'company-edit', params: { companyId: props.row.id } }">{{
          props.row.id
        }}</router-link>
      </b-table-column>

      <b-table-column field="name" :label="$t('companies.name')" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column field="description" :label="$t('companies.description')" v-slot="props">
        {{ props.row.description }}
      </b-table-column>

      <b-table-column :label="$t('companies.reminder')" v-slot="props">
        <span>
          <b-icon v-if="props.row.endOfWeekReminder" pack="fas" icon="check" />
        </span>
      </b-table-column>

      <b-table-column field="date" :label="$t('generic.modifiedDate')" sortable centered v-slot="props">
        <span class="tag">
          {{ props.row.modified }}
        </span>
      </b-table-column>

      <b-table-column field="options" :label="$t('generic.options')" centered v-slot="props">
        <b-button type="is-danger" icon-right="trash" @click="confirmDelete(props.row.id)" />
      </b-table-column>
      <template slot="empty">
        <no-data-to-list />
      </template>
    </b-table>
  </div>
</template>
<script>
import * as actions from '../../../store/actions';
import * as namespaces from '../../../store/namespaces';
import logger from '../../../utils/logger';
import NoDataToList from '../_fragments/NoDataToList';

export default {
  name: 'CompaniesList',
  components: { NoDataToList },
  data() {
    return {
      isMenuActive: true,
      loading: false,
      loadingError: false,
      deleteError: false
    };
  },
  computed: {
    companies() {
      let data = [];
      if (this.$store.state.companies.all) {
        this.$store.state.companies.all.forEach(item => {
          data.push({
            id: item._id,
            name: item.name,
            description: item.description,
            endOfWeekReminder: item.endOfWeekReminder,
            modified: new Date(item.updatedAt).toLocaleDateString()
          });
        });
      }
      return data;
    }
  },
  mounted() {
    this.loading = true;
    this.loadingError = false;
    this.$store
      .dispatch(namespaces.COMPANIES + actions.FETCH_COMPANIES)
      .catch(error => {
        this.loadingError = true;
        logger.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    confirmDelete(companyId) {
      this.$buefy.dialog.confirm({
        message: this.$t('companies.confirmDelete'),
        cancelText: this.$t('generic.cancelButton'),
        confirmText: this.$t('generic.yesButton'),
        focusOn: 'cancel',
        onConfirm: () => {
          this.$store
            .dispatch(namespaces.COMPANIES + actions.DELETE_COMPANY, companyId)
            .then(() => {
              this.deleteError = false;
            })
            .catch(error => {
              this.deleteError = true;
              logger.error(error);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
